import React, { ReactChild, ReactElement } from 'react'
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout'
import { main, article } from '../../styles/layout.css';
import * as global from '../../styles/global.css';
import { atoms } from '../../styles/sprinkles.css';
import { titleLink, blogPageLi, blogPageUl, date } from '../../styles/blogIndex.css';
import SEO from '../../components/seo';

interface BlogProps {
  data: any
}

const BlogPage = ({ data }: BlogProps) => {
  return (
    <Layout pageTitle="Posts">
      <div className={article}>

        <h2 className={global.h2}>The blog</h2>
        {
          data.allMdx.nodes.map((node: any) => {
            return (
              <>
                <ul className={blogPageUl}>
                  <li className={blogPageLi}>
                    <h3>
                      <Link to={`/blog/${node.slug}`} className={titleLink}>
                        {node.frontmatter.title}
                      </Link>
                    </h3>
                    <p className={global.date}>{node.frontmatter.date}</p>
                    <p>{node.frontmatter.description}</p>
                  </li>
                </ul>
              </>
            )
          })

        }
      </div>
    </Layout>
  )
}

export const query = graphql`
      query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
        nodes {
        frontmatter {
        date(formatString: "YYYY-MM-DD")
        description
      title
        }
      id
      slug
      timeToRead
      }
    }
  }
`

export default BlogPage;